//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Button } from "element-ui";
import { ctrlOrder, ctrlPay, ctrlBase, ctrlCpn } from "@/controller";
import { CouponGet } from "@/components";
export default {
    components: {
        [Button.name]: Button,
        CouponGet,
    },
    data() {
        return {
            orderDtl: {}, //訂單詳情
            payList: [], //配送方式
            ctrlBase,
            newCpnlist: [], //優惠券列表
        };
    },
    created() {
        this.getDetail();
        this.getPayList();
    },
    computed: {
        title() {
            if (["pledge", "shiping", "closed"].includes(this.orderDtl.SoInfo.stat)) {
                return "付款成功";
            } else {
                return "送出成功";
            }
        },
        //是否線上付款
        isOnline() {
            if (this.Common.isExist(this.payList)) {
                for (let item of this.payList) {
                    if (item.payNbr == this.orderDtl.SoInfo.payNbr) {
                        return item.isOnline;
                    }
                }
            }
        },
        /**
         * 線下付款說明
         */
        offlineDesc() {
            //未付款&&線下付款
            if (!this.isOnline) {
                if (["pledge", "shiping", "closed"].includes(this.orderDtl.SoInfo.stat)) {
                    return `請在 ${this.orderDtl.SoInfo.payDate} 前完成匯款並填寫匯款資訊，逾期訂單將自動關閉`;
                }
            }
        },
        //付款說明
        payDes() {
            if (this.Common.isExist(this.payList)) {
                const payNbr = this.orderDtl.SoInfo.payNbr;
                let desc = null;
                for (let item of this.payList) {
                    if (item.payNbr == payNbr) {
                        desc = item.desc;
                        break;
                    }
                }
                return desc;
            }
        },
        disc() {
            if (this.Common.isExist(this.orderDtl.SoInfo)) {
                let amtDisc = 0;
                let amtPromo = 0;
                if (this.Common.isExist(this.orderDtl.SoInfo.amtDisc)) amtDisc = Number(this.orderDtl.SoInfo.amtDisc);
                if (this.Common.isExist(this.orderDtl.SoInfo.amtPromo)) amtPromo = Number(this.orderDtl.SoInfo.amtPromo);

                return amtDisc + amtPromo;
            }
        },
        configInfo() {
            return this.$store.state.base.configInfo;
        },
    },
    methods: {
        //獲取訂單明細
        async getDetail() {
            this.orderDtl = await ctrlOrder.getDetail(this.$route.params);
            if (this.orderDtl.SoInfo.stat == "pledge") {
                setTimeout(() => {
                    //已付款 領券
                    this.collectCouponByOrder();
                }, 1500);
            }
        },
        //獲取配送方式
        async getPayList() {
            this.payList = await ctrlPay.getPayList();
        },
        async collectCouponByOrder() {
            const data = await ctrlCpn.collectCouponByOrder({ soNbr: this.orderDtl.SoInfo.soNbr });
            if (data) {
                this.newCpnlist = data;
                this.$refs.couponGet.couponShow = true;
            }
        },
    },
};
